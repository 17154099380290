/**
 * Allowed image mime types.
 *
 * @version 1.0.0
 *
 * @type {string[]}
 */
export const imageMimeTypes = [
    'image/jpeg',
    'image/png'
]

/**
 * Allowed video mime types.
 *
 * @type {string[]}
 */
export const videoMimeTypes = [
    'video/x-msvideo',
    'video/mp4',
    'video/mpeg',
    'video/ogg',
    'video/webm'
]
