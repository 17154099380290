/**
 * Swap array items.
 *
 * @param {Number} indexA
 * @param {Number} indexB
 * @param {Array} array
 * @returns {Array}
 */
function swapItems (indexA, indexB, array) {
    const temp = array[indexA]
    
    array[indexA] = array[indexB]
    array[indexB] = temp
    
    return array
}

export default swapItems