<template>
    <div :id="`video-card-${id}`" class="card mb-4">
        <div class="card-body">
            <div class="row g-2">
                <div class="col-12">
                    <label :for="`video-title-${id}`" class="form-label fw-bold mb-1">Názov *</label>
                    <input :id="`video-title-${id}`" v-model="input.title.value" :class="'form-control ' + isValidInput(input.title)" type="text">
                </div>
                <div class="col-12">
                    <label :for="`video-file-${id}`" class="form-label fw-bold mb-1">Súbor *</label>
                    <div :class="{'d-none': ! videoSrc}" class="mt-1 mb-2">
                        <video ref="video"></video>
                    </div>
                    <div class="d-flex">
                        <label
                            ref="fileLabel"
                            :for="`video-file-${id}`"
                            :class="'form-control file-label ' + isValidInput(input.file)"
                        >
                            Vyberte video
                        </label>
                        <input
                            :id="`video-file-${id}`"
                            ref="fileInput"
                            :accept="acceptVideoMimeTypes"
                            type="file"
                            @change="handleFileInputChange"
                        >
                        <button v-if="videoSrc" class="btn btn-danger ms-1" @click.prevent="handleFileInputDelete">
                            <i class="bi bi-trash-fill"></i>
                        </button>
                    </div>
                
                </div>
                <div class="col-12">
                    <label :for="`video-group-${id}`" class="form-label fw-bold mb-1">Skupina</label>
                    <select :id="`video-group-${id}`" :value="input.group.value" @change="handleGroupChange" class="form-select form-control">
                        <option value="" :selected="input.group.value === ''">-</option>
                        <option v-for="group in groupsArray">{{ group }}</option>
                    </select>
                </div>
                <div class="col-12">
                    <label :for="`video-description-${id}`" class="form-label fw-bold mb-1">Popis</label>
                    <textarea :id="`video-description-${id}`" v-model="input.description.value" :class="'form-control ' + isValidInput(input.description)" rows="4"></textarea>
                </div>
                <div class="controls">
                    <button class="btn" type="button" @click.prevent="moveUp">
                        <i class="bi bi-arrow-up-short"></i>
                    </button>
                    <button class="btn" type="button" @click.prevent="moveDown">
                        <i class="bi bi-arrow-down-short"></i>
                    </button>
                    <button class="btn" type="button" @click.prevent="handleComponentRemove">
                        <i class="bi bi-x" style="color: var(--bs-red)"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { anyPass, forEachObjIndexed, includes, isEmpty, isNil, join, map, pipe, reject, trim } from 'ramda'
import { validInput } from '@/util/validInput'
import { formInput } from '@/util/formInput'
import v8n from 'v8n'
import { videoMimeTypes } from '@/util/file'
import Plyr from 'plyr'
import { getUnixTime } from 'date-fns'
import humanFileSize from '../util/humanFileSize'

export default {
    beforeDestroy () {
        this.plyr?.destroy()
        
        if (this.videoSrc) {
            URL.revokeObjectURL(this.videoSrc)
        }
    },
    computed: {
        acceptVideoMimeTypes () {
            return join(',', videoMimeTypes)
        },
        accessToken () {
            return this.$store.getters['auth/selectAccessToken']
        },
        groupsArray () {
            return pipe(map(trim), reject(anyPass([isNil, isEmpty])))(this.groups)
        },
    },
    created () {
        const self = this
        
        forEachObjIndexed(function (inputObj, key) {
            self.$watch(
                function () {
                    return inputObj?.value
                },
                function (value) {
                    const input = self.input[key]
                    
                    if (! input) return
                    
                    input.dirty = true //  (input.value !== input.oldValue)
                    input.valid = input.validation(value)
                    
                    this.handleChange && this.handleChange(this.id, {
                        title: this.input.title.value || '',
                        file: this.input.file.value || null,
                        group: this.input.group.value || '',
                        dirty: input.dirty,
                        id: this.id,
                        description: this.input.description.value || '',
                    })
                },
            )
        }, this.input)
    },
    data () {
        return {
            input: {
                title: formInput({
                    value: this.payload?.title || '',
                    oldValue: this.payload?.title || '',
                    validation: value => v8n()
                        .string()
                        .not
                        .empty()
                        .test(value),
                }),
                group: formInput({
                    value: this.payload?.group || '',
                    oldValue: this.payload?.group || '',
                    validation: value => v8n()
                        .string()
                        .not
                        .empty()
                        .test(value),
                }),
                description: formInput({
                    valid: true,
                    dirty: true,
                    value: this.payload?.description?.replaceAll('<br />', '') || '',
                    oldValue: this.payload?.description || '',
                    validation: value => v8n()
                        .optional(
                            v8n()
                                .string(),
                            true,
                        )
                        .test(value),
                }),
                file: formInput({
                    validation: value => ! (value?.size === 0 || ! includes(value?.type, videoMimeTypes) || ! (value instanceof File)),
                }),
            },
            plyr: null,
            videoSrc: null,
        }
    },
    mounted () {
        const title = this.input.title
        const group = this.input.group
        const description = this.input.description
        const file = this.input.file
        const payloadFile = this.payload?.file
        
        if (!! title.value) {
            title.dirty = true
            title.valid = title.validation(title.value)
        }
        
        if (!! group.value) {
            group.dirty = true
            group.valid = group.validation(group.value)
        }
        
        if (!! description.value) {
            description.dirty = true
            description.valid = description.validation(description.value)
        }
        
        if (payloadFile) {
            file.dirty = true
            file.valid = file.validation(payloadFile)
            
            this.$refs.fileLabel.innerHTML = `Názov: ${payloadFile?.name}&nbsp;&nbsp;&nbsp;&nbsp;Veľkosť: ${humanFileSize(payloadFile?.size, true)}`
        }
        
        // init plyr
        this.plyr = new Plyr(this.$refs.video, {
            fullscreen: {
                enabled: true,
                fallback: true,
                iosNative: true,
                container: null,
            },
            ratio: '16:9',
        })
        
        if (!! this.payload?.src) {
            this.videoSrc = this.payload?.src
        }
        
        if (!! this.payload?.file) {
            this.videoSrc = URL.createObjectURL(this.payload.file)
        }
    },
    methods: {
        handleComponentRemove () {
            this.handleComponentDelete && this.handleComponentDelete(this.id)
        },
        handleFileInputChange (event) {
            const file = event.target.files[0]
            
            this.$refs.fileLabel.innerHTML = `Názov: ${file?.name}&nbsp;&nbsp;&nbsp;&nbsp;Veľkosť: ${humanFileSize(file?.size, true)}`
            this.input.file.value = file
            this.videoSrc = URL.createObjectURL(file)
        },
        handleFileInputDelete () {
            URL.revokeObjectURL(this.videoSrc)
            
            this.$refs.fileLabel.innerHTML = 'Vyberte video'
            this.input.file.value = null
            this.videoSrc = null
            this.$refs.fileInput.value = null
            this.plyr.source = {
                type: 'video',
                sources: [],
            }
        },
        handleGroupChange (event) {
            this.input.group.value = event?.target?.value
        },
        isValidInput (input) {
            return validInput(input)
        },
        moveDown () {
            this.handleMoveDown && this.handleMoveDown(this.id)
        },
        moveUp () {
            this.handleMoveUp && this.handleMoveUp(this.id)
        },
    },
    name: 'VideoUpload',
    props: {
        groups: Array,
        handleComponentDelete: Function,
        handleChange: Function,
        id: String,
        handleMoveDown: Function,
        handleMoveUp: Function,
        payload: Object,
    },
    watch: {
        groups (value) {
            if (! value?.includes(this.input.group.value)) {
                this.input.group.value = ''
                
                this.handleChange && this.handleChange(this.id, {
                    title: this.input.title.value || '',
                    file: this.input.file.value || null,
                    group: this.input.group.value || '',
                    dirty: true,
                    id: this.id,
                    description: this.input.description.value || '',
                })
            }
        },
        videoSrc (value) {
            if (! value) return
            
            const version = getUnixTime(new Date())
            
            this.plyr.source = {
                type: 'video',
                sources: [
                    {
                        src: value.startsWith('blob') ? value : `${value}&version=${version}`,
                    },
                ],
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import '../resource/scss/bootstrap';

button {
    height: 40px;
}

.card {
    .card-body {
        position: relative;
        
        .controls {
            display: inline-flex;
            margin: 0;
            padding: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: auto;
            
            .btn {
                align-items: center;
                display: flex;
                justify-content: center;
                min-width: 2rem;
                padding: 0;
                width: auto;
                
                i {
                    font-size: 2rem;
                }
            }
            
        }
    }
    
    .file-label {
        background: #e9ecef;
        cursor: pointer;
    }
    
    input[type="file"] {
        display: none;
        visibility: hidden;
    }
}

input, textarea, .form-control {
    border-color: $dark;
}
</style>
